import { OwcIconButton, OwcListItem, OwcMenu } from "@one/react";
import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { v4 } from "uuid";
import { EQUIPMENT_REVIEW_ACTIONS, entryType, equipmentStatus } from "../../../constants";
import { isId4eye } from "../../../utils/helpers/4eye";

const EquipmentAction = ({
  params,
  editEquipment,
  loadAuditTrailList,
  deleteOrRestoreEquipment,
  actionStatus,
  groups,
  fullUserID,
  onReviewOrRevert
}) => {
  const [is4epNewEquipment, setIs4epNewEquipment] = useState(false);
  const [reviewId, setReviewId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(false);
  const [hasPermission, setHasPermission] = useState(actionStatus?.hasRole === "Admin");
  const handleSettingClose = () => {
    setAnchorEl(false);
  };
  const handleSettingClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    const id = params.data?.id?.[0];
    if (isId4eye(id)) {
      setIs4epNewEquipment(true);
    }
    setReviewId(id);
  }, [params]);

  useEffect(() => {
    if (actionStatus.hasRole === "User") {
      groups.forEach((x) => {
        if (params?.data?.id[0] !== "dummyRow" && params?.data["belonging_to_group"][0].startsWith(x.groupName)) {
          setHasPermission(true);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHasPermission]);
  const menuId = v4();

  const userCanRevertNew4epEquipment = () =>
    is4epNewEquipment && params?.data?.status?.[0] === equipmentStatus?.pending?.key && hasPermission;

  return (
    <>
      {params?.data?.id && params?.data?.id[0] !== "dummyRow" && (
        <>
          <OwcIconButton
            icon="more_vertical"
            type="legacy"
            flat
            onClick={(event) => {
              handleSettingClick(event, params);
            }}
            id={menuId}
          />
          {ReactDOM.createPortal(
            <OwcMenu
              data-testid="simple-menu"
              anchor={menuId}
              visible={anchorEl}
              onClickOutside={handleSettingClose}
              disablePortal={true}
            >
              {is4epNewEquipment &&
                params?.data?.status?.[0] === equipmentStatus?.pending?.key &&
                params?.data?.reviewers?.includes(fullUserID) && (
                  <OwcListItem
                    onClick={() => {
                      setAnchorEl(null);
                      onReviewOrRevert(EQUIPMENT_REVIEW_ACTIONS?.review?.value, reviewId);
                    }}
                  >
                    Review
                  </OwcListItem>
                )}
              {userCanRevertNew4epEquipment() && (
                <OwcListItem
                  onClick={() => {
                    setAnchorEl(null);
                    onReviewOrRevert(EQUIPMENT_REVIEW_ACTIONS?.revert?.value, reviewId);
                  }}
                >
                  Revert
                </OwcListItem>
              )}
              {params?.data?.status?.[0] !== equipmentStatus?.pending?.key && (
                <OwcListItem
                  data-testid="simple-menu-filter"
                  onClick={() => {
                    setAnchorEl(null);
                    editEquipment({
                      id: params?.data?.id[0],
                      entryType: params?.data?.entry_type[0]
                    });
                  }}
                  disabled={
                    !(
                      actionStatus.showEdit &&
                      hasPermission &&
                      params?.data?.status?.[0] === equipmentStatus?.active?.key
                    )
                  }
                >
                  Edit
                  {params?.data?.entry_type?.[0] === entryType?.cluster ? " cluster" : " equipment"}
                </OwcListItem>
              )}
              {params?.data?.status?.[0] !== equipmentStatus?.pending?.key &&
                params?.data?.entry_type?.[0] !== entryType?.clusterSubequipment &&
                !params?.data?.cluster_id && (
                  <OwcListItem
                    data-testid="simple-menu-filter"
                    onClick={() => {
                      setAnchorEl(null);
                      deleteOrRestoreEquipment({
                        id: params?.data?.id[0]
                      });
                    }}
                    disabled={
                      params?.data?.status?.[0] === equipmentStatus?.deleted?.key
                        ? !(actionStatus.showRestore && hasPermission) ||
                          params?.data?.entry_type?.[0] === entryType?.cluster
                        : !(actionStatus.showDelete && hasPermission)
                    }
                  >
                    {params?.data?.status?.[0] === equipmentStatus?.deleted?.key ? "Restore" : "Remove"}
                    {params?.data?.entry_type?.[0] === entryType?.cluster ? " cluster" : " equipment"}
                  </OwcListItem>
                )}
              {!is4epNewEquipment &&
                params?.data?.status?.[0] === equipmentStatus?.pending?.key &&
                params?.data?.reviewers?.includes(fullUserID) && (
                  <OwcListItem
                    data-testid="simple-menu-filter"
                    onClick={() => {
                      setAnchorEl(null);
                      onReviewOrRevert(EQUIPMENT_REVIEW_ACTIONS?.review?.value, params?.data?.id[0]);
                    }}
                  >
                    Review
                  </OwcListItem>
                )}
              {!is4epNewEquipment && params?.data?.status?.[0] === equipmentStatus?.pending?.key && hasPermission && (
                <OwcListItem
                  data-testid="simple-menu-filter"
                  onClick={() => {
                    setAnchorEl(null);
                    onReviewOrRevert(EQUIPMENT_REVIEW_ACTIONS?.revert?.value, params?.data?.id[0]);
                  }}
                >
                  Revert
                </OwcListItem>
              )}

              <OwcListItem
                data-testid="simple-menu-filter"
                onClick={() => {
                  setAnchorEl(null);
                  loadAuditTrailList({ id: params?.data?.id[0], data: params?.data });
                }}
              >
                Audit trail of
                {params?.data?.entry_type?.[0] === entryType?.cluster ? " cluster" : " equipment"}
              </OwcListItem>
            </OwcMenu>,
            document.body
          )}
        </>
      )}
    </>
  );
};

export default EquipmentAction;
