import { find, isBoolean } from "lodash";
import styled from "styled-components";
import { get } from "underscore";
import { DetailsCellStyled } from "./DetailsCellStyled";
import { DetailsCellsWrapperStyled } from "./DetailsCellsWrapperStyled";
import { rearangeDisplayDate, validateSelection } from "./../../../utils/helpers/text/index";
import {
  AUDIT_ACTIONS,
  AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE,
  emptyClusterInformation,
  EQUIPMENT_STATUS_FIELD,
  GXPemptyInstrumentsOptions,
  SHOW_SUB_CLUSTER_KEY
} from "../../../constants";
import { useSelector } from "react-redux";
import DATA_MODEL_TABLE from "../../../utils/constants/dataModelTable";
import { OwcTooltip } from "@one/react";
import React, { Fragment } from "react";
import { AuditItemContentCellsInfoArray as changeFieldConfig } from "../../../features/instruments/audit-trial/AuditItemCellsConfig";

const ChangesCellContainer = styled.div`
  display: flex;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
  color: #000000;
`;

const ClusterCellOld = styled.div`
  min-width: 850px;
  width: 850px;
  text-decoration: line-through;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
`;

const ClusterCellNew = styled.div`
  min-width: 850px;
  width: 850px;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  white-space: nowrap;
`;

const ChangesCellOld = styled.div`
  max-width: 50%;
  margin-right: 10px;
  text-decoration: line-through;
  white-space: pre-wrap;
`;

const ChangesCellNew = styled.div`
  white-space: pre-wrap;
`;

const NoValueCell = styled.span`
  max-width: 50%;
  margin: 0 10px;
`;

const SubEqCellStyled = styled.div`
  color: var(--one-color-gray-800);
  background-color: var(--one-color-gray-50);
  border-radius: 4px;
  padding: 16px 0 16px 16px;
`;

const WrapperStyled = styled.div`
  width: 100%;
  background-color: var(--one-color-gray-50);
  border-radius: 4px;
  border: thin solid var(--one-color-gray-300);
`;

const AuditTrialProposedTitle = styled.span`
  font-size: 14px;
  line-height: 23px;
  font-weight: 500;
  color: #000000;
  padding: 20px 16px 20px 16px;
`;

const SingleDetailsCell = ({ cellValue }) => {
  if (isBoolean(cellValue?.oldvalue)) {
    cellValue.oldvalue = String(cellValue?.oldvalue);
  }
  if (isBoolean(cellValue?.newvalue)) {
    cellValue.newvalue = String(cellValue?.newvalue);
  }
  return (
    <ChangesCellContainer>
      {cellValue?.oldvalue && cellValue?.oldvalue !== "null" ? (
        <>
          <ChangesCellOld id={cellValue.oldvalue}>{cellValue.oldvalue}</ChangesCellOld>
          <OwcTooltip anchor={cellValue.oldvalue}>{cellValue.oldvalue}</OwcTooltip>
        </>
      ) : (
        <NoValueCell>-</NoValueCell>
      )}
      {cellValue?.newvalue && cellValue?.newvalue !== "null" ? (
        <>
          <ChangesCellNew id={cellValue.newvalue}>{cellValue.newvalue}</ChangesCellNew>
          <OwcTooltip anchor={cellValue.newvalue}>{cellValue.newvalue}</OwcTooltip>
        </>
      ) : (
        <NoValueCell>-</NoValueCell>
      )}
    </ChangesCellContainer>
  );
};

const ReviewSingleDetailsCell = ({ cellValue, tooltipString = "", valuesInRow = false }) => {
  if (isBoolean(cellValue?.oldvalue)) {
    cellValue.oldvalue = String(cellValue?.oldvalue);
  }
  if (isBoolean(cellValue?.newvalue)) {
    cellValue.newvalue = String(cellValue?.newvalue);
  }
  return (
    <>
      <ChangesCellContainer style={{ flexDirection: valuesInRow ? "row" : "column" }}>
        {cellValue?.value ? (
          <div id={tooltipString}>
            <strong>{cellValue?.value === "null" ? "-" : `${cellValue?.value}...`}</strong>
            <OwcTooltip anchor={tooltipString}>{tooltipString}</OwcTooltip>
          </div>
        ) : (
          <>
            {cellValue?.newvalue && cellValue?.newvalue !== "null" ? (
              <>
                <ChangesCellNew
                  id={cellValue.newvalue}
                  style={{
                    width: valuesInRow ? "50%" : "",
                    color: "var(--one-color-accent-quinary-3)",
                    Size: "16px"
                  }}
                >
                  {cellValue.newvalue}
                </ChangesCellNew>
                <OwcTooltip anchor={cellValue.newvalue}>{cellValue.newvalue}</OwcTooltip>
              </>
            ) : (
              <NoValueCell>-</NoValueCell>
            )}
            {cellValue?.oldvalue && cellValue?.oldvalue !== "null" ? (
              <>
                <ChangesCellOld
                  id={cellValue.oldvalue}
                  style={{
                    color: "var(--one-color-accent-secondary-4)",
                    Size: "16px"
                  }}
                >
                  {cellValue.oldvalue}
                </ChangesCellOld>
                <OwcTooltip anchor={cellValue.oldvalue}>{cellValue.oldvalue}</OwcTooltip>
              </>
            ) : (
              <NoValueCell>-</NoValueCell>
            )}
          </>
        )}
      </ChangesCellContainer>
    </>
  );
};
const MultiLineDetailsCell = ({ cellValue }) => {
  return (
    <>
      {Array.isArray(cellValue)
        ? cellValue.map((value) => {
            return <SingleDetailsCell cellValue={value} />;
          })
        : ""}
    </>
  );
};

const ReviewMultiLineDetailsCell = ({ cellValue, label }) => {
  let tooltipString = "";
  cellValue.forEach((item) => {
    tooltipString = item?.value ? tooltipString + `${item?.value}, ` : "";
  });
  return (
    <>
      {Array.isArray(cellValue) ? (
        <>
          {cellValue?.[0]?.value &&
          [
            DATA_MODEL_TABLE.installedTests.value,
            DATA_MODEL_TABLE.sop.value,
            DATA_MODEL_TABLE.qualificationDocuments.value
          ].includes(label) ? (
            <>
              <ReviewSingleDetailsCell cellValue={cellValue?.[0]} tooltipString={tooltipString} />
            </>
          ) : (
            <>
              {cellValue.map((value) => {
                return <ReviewSingleDetailsCell cellValue={value} />;
              })}
            </>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export const DetailsCell = ({
  label,
  cellValue,
  isAuditTrial = false,
  Wrapper,
  isReview = false,
  fullRow,
  multi,
  multiLine,
  ...props
}) => {
  cellValue = cellValue === "null" ? "" : cellValue;

  const RenderedCell = () => {
    if (isReview) {
      if (["boolean", "string"].includes(typeof cellValue))
        return <strong style={{ whiteSpace: "pre-wrap" }}>{cellValue.toString()}</strong>;
      if (multiLine) return <ReviewMultiLineDetailsCell cellValue={cellValue} label={label} />;
      if (label === DATA_MODEL_TABLE.configurationDetails.value)
        return <ReviewSingleDetailsCell cellValue={cellValue} valuesInRow />;
      else return <ReviewSingleDetailsCell cellValue={cellValue} />;
    }
    if (isAuditTrial) {
      if (multiLine) return <MultiLineDetailsCell cellValue={cellValue} />;
      else return <SingleDetailsCell cellValue={cellValue} />;
    }
    return <strong style={{ whiteSpace: "pre-wrap" }}>{cellValue}</strong>;
  };

  return (
    <>
      {cellValue && (
        <Wrapper {...props}>
          <div data-testid="details-cell-label">{label}</div>
          <div>
            <RenderedCell />
          </div>
        </Wrapper>
      )}
    </>
  );
};

const SubClusterCell = ({ items, relevantPositionState, prefixString = null }) => {
  let newArrItems = [];
  items.forEach((item) => {
    if (item?.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
      newArrItems = [item, ...newArrItems];
    } else {
      newArrItems = [...newArrItems, item];
    }
  });
  return (
    <div
      style={{ maxHeight: "200px", overflow: "auto" }}
      id="ClusterDecommission-ClusterDetailsCells-FrameWithBackground"
    >
      {newArrItems.map((item) => {
        let deleteDivCollector = "";
        let addDivCollector = "";
        let newUpdateDiv = "";
        let oldUpdateDiv = "";

        if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Deleted) {
          if (relevantPositionState) {
            deleteDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            deleteDivCollector += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Created) {
          if (relevantPositionState && item?.positionInCluster) {
            addDivCollector += `Position ${
              item?.positionInCluster !== null &&
              item?.positionInCluster !== "undefined" &&
              item?.positionInCluster !== "null"
                ? item?.positionInCluster
                : " -"
            }: `;
          }
          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            addDivCollector += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        } else if (item.type === AUDIT_SUB_EQUIPMENT_CLUSTER_TYPE.Updated) {
          if (relevantPositionState) {
            newUpdateDiv += `Position ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
            oldUpdateDiv += `Position ${
              item?.oldvalue?.positionInCluster !== null &&
              item?.oldvalue?.positionInCluster !== "undefined" &&
              item?.oldvalue?.positionInCluster !== "null"
                ? item?.oldvalue?.positionInCluster
                : " "
            }: Sub-component- ${
              item?.newvalue?.positionInCluster !== null &&
              item?.newvalue?.positionInCluster !== "undefined" &&
              item?.newvalue?.positionInCluster !== "null"
                ? item?.newvalue?.positionInCluster
                : " "
            }`;
          }

          SHOW_SUB_CLUSTER_KEY.forEach((clusterItem, indexItem) => {
            newUpdateDiv += `${clusterItem?.label}:${
              item?.newvalue?.[clusterItem?.key] !== null &&
              item?.newvalue?.[clusterItem?.key] !== "undefined" &&
              item?.newvalue?.[clusterItem?.key] !== "null"
                ? item?.newvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;

            oldUpdateDiv += `${clusterItem?.label}:${
              item?.oldvalue?.[clusterItem?.key] !== null &&
              item?.oldvalue?.[clusterItem?.key] !== "undefined" &&
              item?.oldvalue?.[clusterItem?.key] !== "null"
                ? item?.oldvalue?.[clusterItem?.key]
                : " -"
            }${indexItem < SHOW_SUB_CLUSTER_KEY.length - 1 ? ", " : ""}`;
          });
        }
        return (
          <>
            <ClusterCellOld id={deleteDivCollector}>
              {prefixString && deleteDivCollector && `${prefixString} : `}
              {deleteDivCollector}
            </ClusterCellOld>
            <OwcTooltip anchor={deleteDivCollector}>{deleteDivCollector}</OwcTooltip>

            <ClusterCellNew id={addDivCollector}>
              {prefixString && addDivCollector && `${prefixString} : `}
              {addDivCollector}
            </ClusterCellNew>
            <OwcTooltip anchor={addDivCollector}>{addDivCollector}</OwcTooltip>

            <ClusterCellOld id={oldUpdateDiv}>
              {prefixString && oldUpdateDiv && `${prefixString} : `}
              {oldUpdateDiv}
            </ClusterCellOld>
            <OwcTooltip anchor={oldUpdateDiv}>{oldUpdateDiv}</OwcTooltip>

            <ClusterCellNew id={newUpdateDiv}>
              {prefixString && newUpdateDiv && `${prefixString} : `}
              {newUpdateDiv}
            </ClusterCellNew>
            <OwcTooltip anchor={newUpdateDiv}>{newUpdateDiv}</OwcTooltip>
          </>
        );
      })}
    </div>
  );
};

const DetailsCells = ({
  isAuditTrial = false,
  isDecommission = false,
  isRestoration = false,
  item,
  Wrapper = DetailsCellsWrapperStyled,
  CellWrapper = DetailsCellStyled,
  relevantPositionState,
  auditAction,
  proposedChanges,
  proposedChangesValues
}) => {
  let changedKeys = Object.keys(item);
  let changedStatusKey = Object.keys(proposedChangesValues);
  let displayCond = true;
  const linkedInstanceList = useSelector((state) => state.instruments?.linkedInstanceList);
  if (auditAction === "CLUSTER_UPDATE") {
    displayCond = false;
    let allFieldKeys = Object.keys(emptyClusterInformation);
    let arr = [];
    for (let key of changedKeys) {
      arr.push(allFieldKeys.includes(key));
    }
    if (arr.includes(true)) {
      displayCond = true;
    }
  }
  if (auditAction === AUDIT_ACTIONS.APPROVE || isDecommission || isRestoration) {
    let changedStatusKeys = changedKeys;
    changedKeys = changedKeys.filter((item) => item !== DATA_MODEL_TABLE?.status?.key);
    changedStatusKey = changedStatusKeys.filter((item) => item === DATA_MODEL_TABLE?.status?.key);
  }

  const getChangedApprovedValues = (changedItemConfig, changes) => {
    if (changedItemConfig?.field === DATA_MODEL_TABLE?.status?.key) {
      return {
        oldvalue: EQUIPMENT_STATUS_FIELD[changes?.oldvalue],
        newvalue: EQUIPMENT_STATUS_FIELD[changes?.newvalue]
      };
    }

    return changes;
  };

  const getChangedValuesArray = (changedItemConfig, changes) => {
    if (
      [
        DATA_MODEL_TABLE.dateOfNextMaintanance.key,
        DATA_MODEL_TABLE.dateOfLastMaintanance.key,
        DATA_MODEL_TABLE.dateOfNextPeriodicReview.key
      ].includes(changedItemConfig?.field)
    ) {
      return {
        oldvalue: changes?.oldvalue ? rearangeDisplayDate(changes?.oldvalue) : null,
        newvalue: changes?.newvalue ? rearangeDisplayDate(changes?.newvalue) : null
      };
    }
    if (
      [DATA_MODEL_TABLE.room.key, DATA_MODEL_TABLE.floor.key, DATA_MODEL_TABLE.buildingLocation.key, "module"].includes(
        changedItemConfig?.field
      )
    ) {
      if (changes?.value) {
        return changes?.value || null;
      } else {
        if (
          changes?.oldvalue?.value === changes?.newvalue?.value ||
          (!changes?.oldvalue?.value && !changes?.newvalue?.value)
        ) {
          return null;
        }
        return {
          oldvalue: changes?.oldvalue?.value,
          newvalue: changes?.newvalue?.value
        };
      }
    }
    if (changedItemConfig?.field === "linkedInstance") {
      const oldvalue = find(linkedInstanceList, {
        linkedInstance: changes?.oldvalue
      });
      const newvalue = find(linkedInstanceList, {
        linkedInstance: changes?.newvalue
      });
      return {
        oldvalue: oldvalue?.linkedInstanceDisplay,
        newvalue: newvalue?.linkedInstanceDisplay
      };
    }
    if (changedItemConfig?.field === "sop") {
      return getModifiedChanges(changes, "value");
    }
    if (changedItemConfig?.field === DATA_MODEL_TABLE.qualificationDocuments.key) {
      if (changes?.value) {
        return getModifiedChanges(changes?.value, "name", "documentId");
      }
    }

    if (changedItemConfig?.field === "qualificationStatus") {
      const oldValue = validateSelection(GXPemptyInstrumentsOptions.qualificationStatus, { key: changes?.oldvalue });

      const newvalue = validateSelection(GXPemptyInstrumentsOptions.qualificationStatus, { key: changes?.newvalue });
      return {
        oldvalue: oldValue,
        newvalue: newvalue
      };
    }
    if (changedItemConfig?.field === "installedTests") {
      return getModifiedChanges(changes, "version", "name");
    }

    if (
      [DATA_MODEL_TABLE.inventoryNumber.key, DATA_MODEL_TABLE.maintenanceIntervalInDays.key].includes(
        changedItemConfig?.field
      )
    ) {
      return {
        oldvalue: changes?.oldvalue?.toString(),
        newvalue: changes?.newvalue?.toString()
      };
    }
    if (changedItemConfig?.field === DATA_MODEL_TABLE?.status?.key) {
      return {
        oldvalue: EQUIPMENT_STATUS_FIELD[changes?.oldvalue],
        newvalue: EQUIPMENT_STATUS_FIELD[changes?.newvalue]
      };
    }

    return changes;
  };

  return !isAuditTrial ? (
    <Wrapper data-testid="details-cells-wrapper">
      {changeFieldConfig.map((field) => {
        let body = get(item, field.field) || "-";
        if (Array.isArray(body) && body.length === 0) {
          body = "-";
        }
        if (field.component) {
          const Component = field?.component;

          body = <Component item={item} />;
        }

        return (
          <DetailsCell
            Wrapper={CellWrapper}
            label={field?.label}
            {...(field?.props ?? {})}
            cellValue={body}
          ></DetailsCell>
        );
      })}
    </Wrapper>
  ) : (
    <WrapperStyled>
      {displayCond && proposedChanges && (
        <Fragment>
          <Wrapper data-testid="audit-trail-details-cells-wrapper">
            {changedStatusKey.map((fieldId) => {
              const changedItemConfig = find(changeFieldConfig, {
                field: fieldId
              });
              const changedItemValues = getChangedValuesArray(changedItemConfig, proposedChangesValues[fieldId]);
              return (
                <Fragment>
                  {changedItemConfig && fieldId === DATA_MODEL_TABLE?.status?.key ? (
                    <DetailsCell
                      key={`${fieldId}-DetailsCell`}
                      isAuditTrial={isAuditTrial}
                      Wrapper={CellWrapper}
                      label={changedItemConfig?.label}
                      {...(changedItemConfig?.props ?? {})}
                      cellValue={changedItemValues}
                    ></DetailsCell>
                  ) : (
                    ""
                  )}
                </Fragment>
              );
            })}
          </Wrapper>
          {!isDecommission && !isRestoration && <AuditTrialProposedTitle>Proposed changes</AuditTrialProposedTitle>}
        </Fragment>
      )}
      {displayCond && auditAction === AUDIT_ACTIONS.APPROVE && (
        <Wrapper data-testid="audit-trail-details-cells-wrapper">
          {changedStatusKey.map((fieldId) => {
            const changedItemConfig = find(changeFieldConfig, {
              field: fieldId
            });
            const changedItemValues = getChangedApprovedValues(changedItemConfig, item[fieldId]);
            return (
              <>
                {changedItemConfig && fieldId === DATA_MODEL_TABLE?.status?.key ? (
                  <DetailsCell
                    isAuditTrial={isAuditTrial}
                    Wrapper={CellWrapper}
                    label={changedItemConfig?.label}
                    {...(changedItemConfig?.props ?? {})}
                    cellValue={changedItemValues}
                  ></DetailsCell>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Wrapper>
      )}
      {displayCond && !isDecommission && !isRestoration && auditAction !== AUDIT_ACTIONS.APPROVE && (
        <Wrapper data-testid="audit-trail-details-cells-wrapper">
          {changedKeys.map((fieldId) => {
            const changedItemConfig = find(changeFieldConfig, {
              field: fieldId
            });
            const changedItemValues = getChangedValuesArray(changedItemConfig, item[fieldId]);

            if (fieldId === DATA_MODEL_TABLE.tags.key) {
              return (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gridColumn: "1 / -1"
                  }}
                >
                  <>
                    <div data-testid="details-cell-label" id={DATA_MODEL_TABLE.tags.key}>
                      {DATA_MODEL_TABLE.tags.value}
                    </div>
                    <OwcTooltip anchor={DATA_MODEL_TABLE.tags.key}>{DATA_MODEL_TABLE.tags.value}</OwcTooltip>
                  </>
                  <div
                    style={{
                      fontSize: "14px",
                      lineHeight: "18px",
                      fontWeight: "500",
                      color: "#000000"
                    }}
                  >
                    <TagLogComponent item={item[fieldId]} />
                  </div>
                </div>
              );
            }

            return (
              <>
                {changedItemConfig && changedItemValues ? (
                  <DetailsCell
                    style={{
                      gridColumn: changedItemConfig?.field === "configurationDetails" ? "1 / span 3" : "initial"
                    }}
                    isAuditTrial={isAuditTrial}
                    Wrapper={CellWrapper}
                    label={changedItemConfig?.label}
                    {...(changedItemConfig?.props ?? {})}
                    cellValue={changedItemValues}
                  ></DetailsCell>
                ) : (
                  ""
                )}
              </>
            );
          })}
        </Wrapper>
      )}
      <SubEqCellStyled>
        {item.hasOwnProperty("subEquipment") && item?.subEquipment.length > 0 && (
          <SubClusterCell items={item?.subEquipment} relevantPositionState={relevantPositionState} />
        )}
        {item.hasOwnProperty("leadingClusterSoftware") && Object.keys(item?.leadingClusterSoftware).length > 0 && (
          <SubClusterCell
            items={[item?.leadingClusterSoftware]}
            relevantPositionState={relevantPositionState}
            prefixString={"Leading cluster software"}
          />
        )}
      </SubEqCellStyled>
    </WrapperStyled>
  );
};
export default DetailsCells;

const concatChanges = (changes, primaryKey, secondaryKey) => {
  if (changes) {
    const primaryValue = changes[primaryKey] ? `${changes[primaryKey]}` : "";
    const secondaryValue = changes[secondaryKey] ? `, ${changes[secondaryKey]}` : "";
    return `${primaryValue}${secondaryValue}`;
  } else {
    return "";
  }
};

export const getModifiedChanges = (changes, primaryKey, secondaryKey) => {
  return changes.map((item) => {
    const itemWithPrimaryKey = item[primaryKey];
    if (itemWithPrimaryKey) {
      if (primaryKey === "version") {
        return {
          oldvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.oldvalue}`,
          newvalue: `${itemWithPrimaryKey[secondaryKey]}, ${itemWithPrimaryKey?.newvalue}`
        };
      } else {
        return {
          oldvalue: `${itemWithPrimaryKey?.oldvalue}, ${itemWithPrimaryKey[secondaryKey]}`,
          newvalue: `${itemWithPrimaryKey?.newvalue}, ${itemWithPrimaryKey[secondaryKey]}`
        };
      }
    }
    if (primaryKey === "version") {
      return {
        oldvalue: concatChanges(item?.oldvalue, secondaryKey, primaryKey),
        newvalue: concatChanges(item?.newvalue, secondaryKey, primaryKey)
      };
    } else {
      return {
        oldvalue: concatChanges(item?.oldvalue, primaryKey, secondaryKey),
        newvalue: concatChanges(item?.newvalue, primaryKey, secondaryKey)
      };
    }
  });
};

export const TagLogComponent = ({ item = [] }) => {
  const html = item.map((x, index) =>
    x?.type === "Created" ? (
      <span key={index}>{x?.newvalue}</span>
    ) : (
      x?.oldvalue &&
      x?.oldvalue !== "null" && (
        <Fragment key={index}>
          <span style={{ textDecoration: "line-through" }} id={x?.oldvalue}>
            {x?.oldvalue}
          </span>
          <OwcTooltip anchor={x?.oldvalue}>{x?.oldvalue}</OwcTooltip>
        </Fragment>
      )
    )
  );
  return <>{html.map((x, index) => (index === 0 ? x : <Fragment key={index}>, {x}</Fragment>))}</>;
};
