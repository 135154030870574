import { useEffect, useState } from "react";
import styled from "styled-components";
import { getAllData } from "../../utils/helpers/fetching";
import { LIST_DIGITAL_LAB_INSTRUMENT_REASON } from "../../gql/landingapi";
import { DEFAULT_RECORDS_FETCHED, OTHER_REASON } from "../../constants";
import { connect } from "react-redux";
import { compose } from "redux";
import { withApollo } from "react-apollo";
import { GridAutocomplete } from "@digitallab/grid-common-components";
import { GridAutoCompleteStyles } from "../../components/shared/GridAutoCompleteStyles";
import { OwcTextarea, OwcTypography } from "@one/react";
import { find } from "lodash";
import CustomSearchText from "../../components/shared/CustomSearchText";

const ReasonForm = styled.div`
  display: flex;
  flex-direction: column;

  & > .customSection {
    display: flex;
    flex-direction: row;
    width: 100%;

    & > .customSelect {
      width: 50%;
      margin-right: 20px;
    }
  }

  & > .customSection2 {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > .customSelect2 {
      width: 86%;
    }
  }
`;

const RefactoredReasonDropdown = ({
  client,
  selectedReason,
  setSelectedReason,
  addComment,
  setAddComment,
  selectedReviewers,
  setSelectedReviewers,
  reviewers,
  isReviewerUpdate
}) => {
  const [ reasonList, setReasonList ] = useState([]);
  const [ isError, setError ] = useState(false);
  const [ isOtherReason, setIsOtherReason ] = useState(false);
  const getReasonList = async () => {
    const list = await getAllData({
      client,
      query: LIST_DIGITAL_LAB_INSTRUMENT_REASON,
      variables: {
        limit: DEFAULT_RECORDS_FETCHED
      },
      fetchPolicy: "network-only",
      dataPath: [ "data", "configurationByModelAndCreatedDate" ],
      drillData: false
    });
    const tempList = list.items.map((x) => {
      return { id: x.id, key: x.configText, value: x.configText };
    });
    setReasonList([ ...tempList ]);
  };

  useEffect(() => {
    getReasonList().catch(console.error);
    setSelectedReason("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsOtherReason(selectedReason?.trim?.() === OTHER_REASON);
  }, [ selectedReason ]);

  return (
    <ReasonForm>
      <div className={ "customSection" }>
        <GridAutocomplete
          dataTestid="reason-select"
          className={ "customSelect" }
          size="small"
          sx={ GridAutoCompleteStyles?.gridAutoCompleteLableShrink }
          value={ find(reasonList, { value: selectedReason }) || null }
          optionsList={ reasonList }
          propValue="value"
          propLabel="key"
          label={ isReviewerUpdate ? "Reason *" : "Select reason" }
          onAutoCompleteChange={ (selectedValue) => {
            if (selectedValue !== null) {
              setSelectedReason(selectedValue?.value);
            }
          } }
          disableClearable
        />

        <OwcTextarea
          data-testid={ `textarea-field-edit-comment` }
          className={ "customSelect2" }
          label={ `${ isReviewerUpdate ? "Add c" : "C" }omment ${ isOtherReason ? "*" : "" }` }
          error={ isOtherReason && isError }
          name="editComment"
          validation-mode="realtime"
          onInputReset={ () => setAddComment("") }
          value={ addComment }
          required={ isOtherReason }
          onInputChange={ (event) => {
            setAddComment(event.target.value);
            if (event.target.value === "") {
              setError(true);
            }
          } }
          onInputKeyDown={ (event) => {
            setAddComment(event.target.value);
          } }
          reset
          cols={ 20 }
          maxLength={ 80 }
          minLength={ 0 }
          multiline
          rows={ 1 }
          minRows={ 2 }
        />
      </div>
      { isReviewerUpdate && (
        <div className={ "customSection2" }>
          <OwcTypography
            style={ {
              fontSize: 18,
              margin: "16px 0",
              color: "#333",
              fontWeight: "bold"
            } }
          >
            Reviewer required
          </OwcTypography>
          <CustomSearchText
            id="reviewers"
            name="reviewers"
            className={ "customSelect2" }
            dataTestid={ `reviewers-inputs-reviewers-input` }
            optionsList={ reviewers }
            disabled={ reviewers.length === 0 }
            propValue="key"
            propLabel="value"
            label={reviewers.length === 0 ? "No reviewer available" : "Reviewer required"}
            value={ selectedReviewers || [] }
            setValue={ (newValue) => {
              setSelectedReviewers?.(newValue);
            } }
            fullWidth={ true }
            multiple={ true }
            required={ true }
          />
        </div>
      ) }
    </ReasonForm>
  );
};

export default compose(connect(null), withApollo)(RefactoredReasonDropdown);
