import React from "react";
import DATA_MODEL_TABLE from "../../../../utils/constants/dataModelTable";
import { defaultInputStyle } from "../../../../utils/helpers/text";
import CommonOwcEditableDropDown from "./CommonOwcEditableDropDown";
import { useFormikContext } from "formik";

export const MainDescriptionEditableDropdown = ({ list, handleOnChange, dataModel, required = true, ...args }) => {
  const formik = useFormikContext();
  const requiredMark = required ? " *" : "";
  return (
    <CommonOwcEditableDropDown
      labelValue={false}
      label={`${DATA_MODEL_TABLE[dataModel].value}${requiredMark}`}
      defaultLabel={`${DATA_MODEL_TABLE[dataModel].value}${requiredMark}`}
      keylabel={DATA_MODEL_TABLE[dataModel].key}
      style={defaultInputStyle}
      list={list}
      selected={formik.values?.[dataModel]}
      onChange={handleOnChange}
      required={required}
      helperText={formik.errors[DATA_MODEL_TABLE[dataModel].key]}
      {...args}
    />
  );
};
