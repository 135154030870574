import { OwcIcon, OwcInput } from "@one/react";
import { useFormikContext } from "formik";
import React from "react";
import { DATA_MODEL_TABLE } from "@digitallab/grid-common-components";

export const OwcInputCustomized = ({
  style = {},
  assistiveText = "",
  required = false,
  dataModel,
  valueValidation,
  onBlurValidation,
  dataTestid,
  ...args
}) => {
  const dataName = DATA_MODEL_TABLE[dataModel].key;
  const label = DATA_MODEL_TABLE[dataModel].value;
  const formik = useFormikContext();
  const isTouched = formik.touched?.[dataName] === true;
  const value = valueValidation ? valueValidation(formik?.values?.[dataName]) : formik?.values?.[dataName];
  return (
    <OwcInput
      variant="filled"
      style={{
        width: "100%",
        ...style
      }}
      data-testid={dataTestid}
      name={dataName}
      id={dataName}
      label={`${label}${required ? " *" : ""}`}
      value={value}
      onInputChange={formik.handleChange}
      onBlur={(event) => {
        formik.handleBlur(event);
        if (onBlurValidation) {
          formik.setFieldValue(dataName, onBlurValidation(formik.values?.[dataName]), true);
        }
      }}
      required={required}
      {...args}
      error={isTouched && formik.errors[dataName]}
    >
      {formik.values?.[dataName] !== "" && isTouched && (
        <OwcIcon
          name="circle_clear_filled"
          slot="suffix"
          type="legacy"
          onClick={() => formik.setFieldValue([dataName], "", true)}
        />
      )}
      <span slot="assistive-text">{assistiveText}</span>
      {isTouched && <span slot="error-text">{formik.errors[dataName]}</span>}
    </OwcInput>
  );
};
